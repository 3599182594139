<template>
  <div>
    <v-file-input
      :value="file"
      :label="required ? label + '*' : label" 
      outlined
      @change="updateFile"
      background-color="white"
      :accept="accept"
      :error="errors && errors.length > 0"
      :class="errors && errors.length ? 'hasError' : ''"
      hide-details
      :disabled="disabled"
      :clearable="true"
      :prepend-icon="null"
    >
      <template v-slot:prepend-inner><v-icon >mdi-camera</v-icon></template>
      <template v-slot:append><v-icon class="error-icon" v-if="errors && errors.length">mdi-alert</v-icon></template>
    </v-file-input>    

    <ErrorMessages :errors="errors"></ErrorMessages>

    <CropperDialog 
      v-if="showCropperDialog"
      :file="file"
      @close="showCropperDialog = false"
      @emitPhoto="uploadPhoto"
    />

  </div>
</template>

<script>
import CropperDialog from '@/components/shared/CropperDialog.vue'
import { resizeImage, sanitizeFileName } from '@/scripts/helper'
import { getTokenAsync } from '@/plugins/auth'

export default {
    name: 'ImageUpload',
    components: {
      CropperDialog
    },
    props: {
        value: Object,
        rules: String,
        label: String,
        required: Boolean,
        errors: Array,
        aspectRatio: Number,
        disabled: Boolean,
        accept: {
          type: String,
          default: "image/*"
        }
    },
    emits: [
        'change'
    ],
    data() {
        return{
          file: null,
          newValue: null,
          showCropperDialog: false,
        }   
    },
    methods: {
      updateFile(file) {

        this.file = file;
        
        if (!this.file) {
          this.newValue = null;
          this.$emit('change', this.newValue);
          return;
        }

        this.showCropperDialog = true;
        
      },
      async uploadPhoto(file) {


        if (file) {
          file = await resizeImage(file);
          this.uploading = true;
          const requestURL = '/api/TempFile?displayName=' + encodeURI(sanitizeFileName(file.name));
          const options = { headers: {
            "Authorization": "Bearer " + await getTokenAsync()
          } }
          const response = await this.$http.post(requestURL, file, options);

          this.newValue = response.data;

        } else {
          this.newValue = null;
        }

        this.showCropperDialog = false;
        this.$emit('change', this.newValue);
      },
      
    },
}

</script>

<style scoped lang="scss">
    @import '@/style.scss';

    img {
      display: block;
      max-width: 100%;
    }

    ::v-deep .v-input--is-label-active .v-input__prepend-inner,
    ::v-deep .v-input--is-dirty .v-input__prepend-inner, 
    ::v-deep .v-input--is-focused .v-input__prepend-inner,
    ::v-deep .v-input--is-label-active .v-input__prepend-inner,
    ::v-deep .v-input--is-dirty .v-input__prepend-inner, 
    ::v-deep .v-input--is-focused .v-input__prepend-inner {
        margin-top: 22px;
    }


</style>
